import React from "react";
import { IoClose } from "react-icons/io5";

const ProductModal = ({ Modal, data }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-screen overflow-auto bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white p-5 h-full md:h-1/2 w-full md:w-1/2 relative ">
        <IoClose
          onClick={() => Modal(false)}
          className="absolute right-3 top-3 rounded bg-primary_colors text-black cursor-pointer"
        />
        <h1 className="font-bold font-serif text-black text-[30px]">
          {data.name}
        </h1>
        <p className="text-black text-start h-full overflow-scroll p-3">{data.Content}</p>
      </div>
    </div>
  );
};

export default ProductModal;
