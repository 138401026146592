import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { FaLocationDot, FaMobileScreen } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      {" "}
      <div className="transparent p-5">
        <div className="max-w-[1280px] mx-auto ">
          <div className="flex flex-col items-start w-full mt-5 mb-5">
            <h1 className="text-primary font-serif font-extrabold text-[24px] my-3">
              Contact Us
            </h1>
            <div className=" w-full flex flex-col md:flex-row justify-between mb-5">
              <div className="flex  items-center gap-2 text-primary ">
                <FaMobileScreen />
                <h1>8606892461,9388475635</h1>
              </div>
              <div className="flex  items-center gap-2 text-primary ">
                <MdEmail />
                <h1>chefprimecare@gmail.com</h1>
              </div>
              <div className="flex items-start md:items-center gap-2 text-primary mt-1 md:mt-0">
                <FaLocationDot />
                <h1 className="text-start">
                  12/55c, Kizhakkekara, Muvattupuzha, Ernakulam, Kerala - 696661
                </h1>
              </div>
            </div>
          </div>
          <hr />
          <div className="flex flex-col md:flex-row items-start w-full gap-5 mb-10 mt-5">
            <div className="w-full md:w-2/4 flex flex-col items-start">
              <h1 className="text-primary font-serif font-extrabold text-[24px] my-3">
                Connect
              </h1>
              <div className="flex text-primary gap-3">
                <Link to={""}>
                  <FaFacebook className="cursor-pointer" />
                </Link>
                <Link to={""}>
                  <FaInstagram className="cursor-pointer" />
                </Link>
                <Link to={""}>
                  <FaYoutube className="cursor-pointer" />
                </Link>
                <Link to={""}>
                  <FaTwitter className="cursor-pointer" />
                </Link>
              </div>
            </div>

            <div className="w-full flex flex-col items-start">
              <h1 className="text-primary font-serif font-extrabold text-[24px] my-3">
                Links
              </h1>
              <div className="w-full flex gap-5">
                <ul className="flex gap-9 justify-around text-primary">
                  <Link to={"/"}>
                    {" "}
                    <li className="hover:text-black">Home</li>
                  </Link>
                  <Link to={"/products"}>
                    {" "}
                    <li className="hover:text-black">Products</li>
                  </Link>
                  <Link to={"/product/view"}>
                    {" "}
                    <li className="hover:text-black">About Us</li>
                  </Link>
                  <Link to={"/contact-us"}>
                    {" "}
                    <li className="hover:text-black">Contact Us</li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="bg-primary shadow z-50 p-5 transparent">
        <div class="w-full mx-auto max-w-screen-xl p-flex flex-col md:items-center md:justify-center">
          <span class="text-sm text-white sm:text-center ">
            Copy Right © Chef Prime. All Rights Reserved.
          </span>
          <br />
          <span class="text-sm text-white sm:text-center ">
            Developed By
            <a
              href="https://qmarktechnolabs.com/"
              target="blank"
              class="hover:underline hover:text-purple-600"
            >
              {" "}
              Qmark Technolabs
            </a>
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
