import React, { useEffect } from "react";
import Carousal from "../components/product/Carousal";
import Card from "../components/4thSection/Card";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
  }, []);

  return (
    <div className="container mx-auto relative">
      {/* Landing page */}
      <div className="w-full">
        <img
          src={require("../assets/Banner/banner-1.png")}
          alt="banner-1"
          className="w-full md:h-[500px] object-cover"
        />
      </div>

      {/* Our Products */}
      <div className="transparent">
        <div className="max-w-[1280px] flex flex-col justify-center mx-auto p-5 md:px-10 ">
          <div className="absolute top-[1%] md:top-10 right-0">
            <img
              data-aos = "fade-up"
              data-aos-duration="800"
              src={require("../assets/Images/chef.png")}
              alt=""
              className="w-[150px] md:w-[400px]"
            />
          </div>
          <div className="flex flex-col w-full items-start mt-5">
            <h1 className="text-primary font-serif font-extrabold text-[30px] mt-3">
              Our Products
            </h1>
            <p className="text-start">
              Our products are made from the best quality raw materials like
              rice, wheat and spices procured from trusted farmers and suppliers
              across India.
            </p>
          </div>

          <div className="my-10">
            <Carousal />
          </div>
        </div>
      </div>

      {/* About Company */}
      <div id="About" className="p-5 md:py-16 bg-primary transparent">
        <div className="max-w-[1280px] mx-auto  w-full flex flex-col md:flex-row py-5 justify-center gap-3">
          <div className="w-full md:w-3/4 flex flex-col items-start text-white" data-aos = "fade-up"
              data-aos-duration="800">
            <h1 className=" font-serif font-bold text-[30px]">
              About the Company
            </h1>
            <p className="flex text-start justify-start pt-3 pb-8">
              is a fully automated cereal and spice processing company located
              at Elappunkal, Erattupetta in Kottayam District of Kerala. It has
              been manufacturing and marketing high-quality rice products, spice
              powders, etc. s ince 1994. has adhered to stringent quality
              standards to secure and maintain trust and customer satisfaction.
              Presently, it is the biggest seller of breakfast foods in Kerala
              and the brand is an a bsolute customer favourite.
            </p>
            <button onClick={()=>navigate('/products')} className="bg-primary shadow-2xl p-2 px-8 rounded hover:scale-105 duration-300 ease-in-out">
              View More
            </button>
          </div>
          <div className="flex items-center justify-center mt-5 md:mt-0" data-aos = "fade-up"
              data-aos-duration="800">
            <img
              src={require("../assets/product/pro2.png")}
              alt="product2"
              className="object-cover"
            />
          </div>
        </div>
      </div>

      {/* Quality section */}
      <div className="max-w-[1280px] mx-auto p-10">
        <div className="w-full py-10 flex flex-col md:flex-row items-center justify-evenly gap-5 md:gap-0">
          <Card data={"100%"} content={"premium Quality"} />
          <Card data={"10+"} content={"Years of Trust"} />
          <Card data={"1 M+"} content={"Happy Customers"} />
        </div>
      </div>

      {/* Information part */}
      <div className="relative w-full flex items-center justify-center  md:h-[450px] ">
        <div className="absolute top-0  w-full  flex -z-10">
          <img
            src={require("../assets/Banner/bannerAppam-3.png")}
            alt=""
            className="w-full object-cover md:h-[450px]"
          />
        </div>

        <div className="md:w-[1280px] h-full mx-auto z-10 flex flex-col items-end justify-center " data-aos = "fade-up"
              data-aos-duration="800">
          <div>
            <h1 className="text-white font-bold mt-10 mdLmt-0 md:text-[32px] font-serif">
              LET GOODTHINGS RISE
            </h1>
            <p className="flex text-white mt-5 text-[12px] md:text-sm">
              All our time and energy goes into creating the highest quality{" "}
              <br />
              products in all kinds of tasty shapes and forms.
            </p>
            <button onClick={()=>navigate('/contact-us')} className="bg-primary p-2 px-5 rounded mt-5 text-white font-serif hover:scale-105 duration-300 ease-in-out">
              Connect With Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
