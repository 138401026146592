import React, { useEffect } from "react";
import ProductCarousal from "../components/product/ProductCarousal";
import Aos from "aos";

const ProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init();
  }, []);

  return (
    <div className="container mx-auto h-auto w-full flex flex-col items-center">
      <div className="md:h-[400px] mb-5 overflow-hidden rounded-b-xl">
        <img
          src={require("../assets/Banner/Frame 7.png")}
          alt="data"
          className="w-[1280px] rounded-b-xl md:object-cover md:h-[400px] position-bottom"
        />
      </div>

      <div className="max-w-[1280px] md:mt-7 p-5">
        <div
          className="flex flex-col md:flex-row w-full"
          data-aos="fade-up"
          data-aos-duration="900"
        >
          <div className="w-full flex flex-col items-start text-start">
            <h1 className="text-primary font-serif font-bold text-[30px]">
              Products
            </h1>
            <h5 className="font-semibold mb-2">Rice Powder</h5>
            <p>
              Experience the true essence of Kerala's culinary artistry with
              Chef Prime Rice Powder. Our commitment to quality begins with the
              meticulous sourcing of premium White Raw rice, ensuring a
              foundation of authentic flavor for beloved dishes like Idiyappam
              and Pathiri. Beyond breakfast, this versatile rice powder caters
              to a spectrum of culinary delights, seamlessly transitioning from
              morning indulgences to evening snacks. <br /> Carefully hygienically
              packed, each serving promises a burst of freshness, maintaining
              the rich taste and enticing aroma. Embark on a journey through
              Kerala's gastronomic traditions as Chef Prime brings the finest
              quality to your kitchen. Elevate your cooking experience with the
              assurance of consistent excellence and a taste that echoes the
              cultural heritage of Kerala. Chef Prime — where quality and
              tradition unite to create an unparalleled culinary adventure.
            </p>
          </div>

          <div data-aos="fade-up" data-aos-duration="800">
            <img src={require("../assets/product/pro2.png")} alt="" />
          </div>
        </div>
      </div>

      <div className="w-full my-7 bg-gradient-to-b md:bg-gradient-to-r from-primary to-white ">
        <div className="transparent">
          <ProductCarousal />
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
