import React, { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import { ClipLoader } from "react-spinners";

const ContactUs = () => {
  const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

  useEffect(() => {
    window.scrollTo(0, 0);
    emailjs.init(PublicKey);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
  });

  const [loading, setLoading] = useState(false);

  const [successMsg, setSuccessMsg] = useState(null);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = "Please enter valid email";
    }

    if (!formData.comment.trim()) {
      newErrors.comment = "Comment is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setLoading(true);

    try {
      emailjs
        .sendForm(ServiceId, TemplateId, e.target, PublicKey)
        .then((result) => {
          console.log("Email sent successfully!");

          setSuccessMsg("comment sent successfully");

          setTimeout(() => {
            setSuccessMsg(null);
          }, [4000]);

          setFormData({
            name: "",
            email: "",
            phone: "",
            comment: "",
          });

          setLoading(false);

          console.log("Form submitted:", result);
        })
        .catch((error) => {
          console.error("Error sending email:", error);
          setErrors((prev) => ({ ...prev, server: "Something went wrong" }));
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container mx-auto max-w-[1280px] h-auto w-full flex flex-col items-center">
      <div className="md:h-[400px] mb-5 overflow-hidden rounded-b-xl">
        <img
          src={require("../assets/Banner/Frame 7.png")}
          alt="data"
          className="w-[1280px] rounded-b-xl md:object-cover md:h-[400px] position-bottom"
        />
      </div>

      <div className="w-full p-5 flex items-center justify-center" >
        <form
          onSubmit={handleSubmit}
          className="w-full md:w-2/5 h-auto shadow-2xl
       bg-white text-[#585858] rounded-lg p-10 md:p-16 md:mt-[10vh] mb-[10vh]"
        >
          <h1 className="text-primary text-2xl font-serif font-bold mb-4">
            Contact Us
          </h1>

          <div className="mb-4">
            <label className="block font- mb-2 text-left" htmlFor="name">
              Full Name
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="name"
              name="name"
              type="text"
              value={formData.name}
              onChange={handleChange}
            />

            {errors.name && <span style={{ color: "red" }}>{errors.name}</span>}
          </div>

          <div className="mb-4">
            <label className="block font- mb-2 text-left" htmlFor="email">
              Email
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
            />

            {errors.email && (
              <span style={{ color: "red" }}>{errors.email}</span>
            )}
          </div>

          <div className="mb-4">
            <label className="block font- mb-2 text-left" htmlFor="phone">
              Mobile Number
            </label>

            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              name="phone"
              type="phone"
              value={formData.phone}
              onChange={handleChange}
            />

            {errors.phone && (
              <span style={{ color: "red" }}>{errors.phone}</span>
            )}
          </div>

          <div className="mb-6">
            <label className="block font- mb-2 text-left" htmlFor="comment">
              Comment
            </label>

            <textarea
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
              id="comment"
              rows="4"
            ></textarea>

            {errors.comment && (
              <span style={{ color: "red" }}>{errors.comment}</span>
            )}
          </div>

          <button
            disabled={loading}
            className="bg-primary hover:bg-primary-700 w-1/2 sm:w-1/4 font-serif text-white 
           py-2 px-4 focus:outline-none focus:shadow-outline
          rounded-tl-[15px] rounded-br-[15px]"
            type="submit"
          >
            {loading ? <ClipLoader size={16} color="#ffffff" /> : "Send"}
          </button>

          {errors.server && (
            <span style={{ color: "red" }}>{errors.server}</span>
          )}
          {successMsg && <span style={{ color: "green" }}>{successMsg}</span>}
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
