import React, { useEffect } from "react";

const ProductView = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container mx-auto h-auto w-full flex flex-col items-center">
       <div className="h-[400px] mb-5 overflow-hidden rounded-b-xl">
        <img
          src={require("../assets/Banner/Frame 7.png")}
          alt="data"
          className="w-[1280px] rounded-b-xl md:object-cover h-[400px] position-bottom"
        />
      </div>

      <div className="max-w-[1280px] my-10 p-5">
        <div className="flex flex-col md:flex-row w-full">
          <div className="w-full flex flex-col items-start text-start">
            <h1 className="text-primary font-serif font-bold text-[30px]">
              Chef Prime Putt Podi
            </h1>
            <p>
              Our fresh made rice powder is made out of highest quality raw rice
              procured from the best paddy fields and ground to perfection for
              preparing those traditional Kerala foods. Our rice powder is ideal
              for making mouthwatering Appams, Idiyappams, Pathiris and the wide
              varieties of food items prepared from rice flour.The rice powder
              is hygienically packed having minimum or no human interventions in
              fully automatic plant to give the customers the best quality
              product.
            </p>
          </div>

          <div>
            <img src={require("../assets/product/pro4.png")} alt="podi" className="w-[350px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductView;
