import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Layout from './layout/Layout';
import ContactUs from './pages/ContactUs';
import ProductPage from "./pages/ProductPage"
import ProductView from "./pages/ProductView"

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route index path='/' element={<Home />} />
            <Route index path='/products' element={<ProductPage />} />
            <Route index path='/product-view' element={<ProductView />} />
            <Route index path='/contact-us' element={<ContactUs />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
