import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { IoClose, IoMenu } from "react-icons/io5";

const Nav = () => {
  const [modal, setModal] = useState(false);
  const params = useLocation();
  console.log(params?.pathname);

  const ScrollToAbout = () => {
    const target = document.getElementById("About");
    window.scrollTo({
      top: target?.offsetTop,
      behavior: "smooth",
    });
  };

  const NavChanger = () => {
    setModal(!modal);
    ScrollToAbout();
  };

  return (
    <>
      <div className="container mx-auto relative flex flex-col w-full md:hidden z-50">
        <div className="flex fixed top-0 bg-white w-full items-center justify-between px-5 py-3">
          <h1 className="text-xs">
            <span className="">CONTACT NO : </span>
            <span className="text-primary">9388475635</span>
          </h1>
          <div className="flex  text-primary gap-1">
            <Link to={""}>
              <FaFacebook className="cursor-pointer" />
            </Link>
            <Link to={""}>
              <FaInstagram className="cursor-pointer" />
            </Link>
            <Link to={""}>
              <FaYoutube className="cursor-pointer" />
            </Link>
            <Link to={""}>
              <FaTwitter className="cursor-pointer" />
            </Link>
          </div>
        </div>
        <div className="flex fixed top-10 w-full items-center justify-end bg-gradient-to-r from-[#DDA525] to-[#CE8620] px-5 py-5 text-white font-san shadow-lg">
          <div className="left-5 bottom-0 absolute">
            <img
              src={require("../assets/logo.png")}
              alt="Logo"
              className="w-16"
            />
          </div>
          <IoMenu size={28} onClick={() => setModal(!modal)} />
          {modal && (
            <div className="flex flex-col items-center fixed left-0 top-0 bg-white text-black w-full h-screen border  p-2 shadow-lg pb-5">
              <div className="w-full flex justify-between items-center">
                <img
                  src={require("../assets/logo.png")}
                  alt="Logo"
                  className="w-10"
                />
                <IoClose
                  className="hover:text-primary bg-primary text-white rounded-full hover:bg-white"
                  size={20}
                  onClick={() => setModal(false)}
                />
              </div>
              <ul className="flex flex-col items-start gap-3 p-2 w-full mt-7">
                <Link
                  to={"/"}
                  onClick={() => setModal(!modal)}
                  className="w-full hover:text-white hover:bg-primary hover:scale-105 ease-in-out duration-300"
                >
                  <li className="w-full border p-2">Home</li>
                </Link>
                <Link
                  to={"/products"}
                  onClick={() => setModal(!modal)}
                  className="w-full hover:text-white hover:bg-primary hover:scale-105 ease-in-out duration-300"
                >
                  <li className="w-full border p-2">Products</li>
                </Link>
                <Link
                  to={"/"}
                  onClick={NavChanger}
                  className="w-full hover:text-white hover:bg-primary hover:scale-105 ease-in-out duration-300"
                >
                  <li className="w-full border p-2">About Us</li>
                </Link>
                <Link
                  to={"/contact-us"}
                  onClick={() => setModal(!modal)}
                  className="w-full hover:text-white hover:bg-primary hover:scale-105 ease-in-out duration-300"
                >
                  <li className="w-full border p-2">Contact Us</li>
                </Link>
              </ul>
              <div className="bg-primary p-5 absolute bottom-0 w-full flex items-center justify-center font-san text-xs">
                <footer class="">
                  <div class="w-full mx-auto max-w-screen-xl p-flex flex-col md:items-center md:justify-center">
                    <span class="text-sm text-white sm:text-center ">
                      Copy Right © Chef Prime. All Rights Reserved.
                    </span>
                    <br />
                    <span class="text-sm text-white sm:text-center ">
                      Developed By
                      <a
                        href="https://qmarktechnolabs.com/"
                        target="blank"
                        class="hover:underline hover:text-purple-600"
                      >
                        {" "}
                        Qmark Technolabs
                      </a>
                    </span>
                  </div>
                </footer>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="container mx-auto relative flex-col items-center w-full hidden md:flex z-50">
        <div className="bg-white fixed top-0 w-full">
          <div className="w-[1280px] mx-auto flex items-center justify-between px-5 py-3 ">
            <h1 className="ps-24">
              <span className="">CONTACT NO : </span>
              <span className="text-primary">9388475635</span>
            </h1>
            <div className="flex text-primary gap-3">
              <Link to={""}>
                <FaFacebook className="cursor-pointer" />
              </Link>
              <Link to={""}>
                <FaInstagram className="cursor-pointer" />
              </Link>
              <Link to={""}>
                <FaYoutube className="cursor-pointer" />
              </Link>
              <Link to={""}>
                <FaTwitter className="cursor-pointer" />
              </Link>
            </div>
          </div>
        </div>
        <div className="fixed top-10 w-full flex items-center justify-end bg-gradient-to-r from-[#DDA525] to-[#CE8620]  py-5 text-white font-san shadow-2xl">
          <div className="w-[1280px] mx-auto px-5">
            <Link to={'/'}>
              <div className=" bottom-0 absolute">
                <img
                  src={require("../assets/logo.png")}
                  alt="Logo"
                  className="w-24 "
                />
              </div>
            </Link>
            <div className="flex items-center justify-end">
              <ul className="flex items-center gap-3">
                <Link
                  to={"/"}
                  className={`${params?.pathname === "/" && " border-b-2"} `}
                >
                  <li className="">Home</li>
                </Link>
                <Link
                  to={"/products"}
                  className={`${
                    params?.pathname === "/products" && "border-b-2 "
                  } `}
                >
                  <li className={``}>Products</li>
                </Link>
                <Link
                  to={"/"}
                  onClick={ScrollToAbout}
                  className={`${params?.pathname === "" && "border-b-2 "} `}
                >
                  <li className={``}>About Us</li>
                </Link>
                <Link
                  to={"/contact-us"}
                  className={`${
                    params?.pathname === "/contact-us" && "border-b-2 "
                  } `}
                >
                  <li className={``}>Contact Us</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nav;
