import pro1 from "../assets/product/pro1.png"
import pro2 from "../assets/product/pro2.png"
import pro3 from "../assets/product/pro3.png"
import pro4 from "../assets/product/pro4.png"
import pro5 from "../assets/product/pro5.png"
import pro6 from "../assets/product/pro6.jpg"

export const Products = [
    {
        id: 1,
        img: pro1
    },
    {
        id: 2,
        img: pro2
    },
]


export const productDetails = [
    {
        id: 1,
        name: "Putt Podi",
        Content: "Steamed puttu podi is a staple in Kerala cuisine, cherished for its simplicity and unique taste. Prepared from finely ground rice flour, this dish is characterized by its distinctive cylindrical shape, achieved by layering the flour with grated coconut and steaming it in a special cylindrical vessel called a puttu kutti. The dish's versatility shines as it can be paired with an array of accompaniments, from spicy curries like kadala curry (black chickpea curry) or egg curry to sweet options like ripe bananas. The combination of the slightly crumbly, steamed rice with the sweetness of coconut creates a delightful contrast in textures and flavors. Steamed puttu podi holds cultural significance in Kerala households, often enjoyed during breakfast or as an evening snack.Its preparation varies across regions, with some adding subtle spices or using different types of rice flour to enhance the taste. Whether enjoyed in the company of family or relished in solitude, this traditional Kerala dish encapsulates the essence of the region's culinary heritage, offering a wholesome and satisfying dining experience. Experience a delectable and nutritious breakfast with our Steam-Made Puttu Podi, crafted from exclusive rice varieties. Just combine 1 cup of chef prime puttu podi with a cup of water, add salt, let it sit for 20 minutes, then layer it in a puttu maker with coconut scraping. Steam for 3-5 minutes for soft and fluffy results. With no added preservatives, this 1kg pack has a shelf life of 9 months from the manufacturing date, ensuring a wholesome start to your day.",
        ContentShowcase: "Steamed puttu podi is a staple in Kerala cuisine, cherished for its simplicity and unique taste. Prepared from finely ground rice flour, this dish is characterized by its distinctive cylindrical shape, achieved by layering the flour with grated coconut and steaming it in a special cylindrical vessel called a puttu kutti. The dish's versatility shines as it can be paired with an array of accompaniments, from spicy curries like kadala curry (black chickpea curry) or egg curry to sweet options like ripe bananas. The combination of the slightly crumbly, steamed rice with the sweetness of coconut creates a delightful contrast in textures and flavors. Steamed puttu podi holds cultural significance in Kerala households, often enjoyed during breakfast or as an evening snack.Its preparation varies across regions, with some adding subtle spices or using different types of rice flour to enhance the taste.",
        img: pro1
    },
    {
        id: 1,
        name: "Rice powder",
        Content: "Experience the true essence of Kerala's culinary artistry with Chef Prime Rice Powder. Our commitment to quality begins with the meticulous sourcing of premium White Raw rice, ensuring a foundation of authentic flavor for beloved dishes like Idiyappam and Pathiri. Beyond breakfast, this versatile rice powder caters to a spectrum of culinary delights, seamlessly transitioning from morning indulgences to evening snacks. Carefully hygienically packed, each serving promises a burst of freshness, maintaining the rich taste and enticing aroma. Embark on a journey through Kerala's gastronomic traditions as Chef Prime brings the finest quality to your kitchen. Elevate your cooking experience with the assurance of consistent excellence and a taste that echoes the cultural heritage of Kerala. Chef Prime — where quality and tradition unite to create an unparalleled culinary adventure.        ",
        ContentShowcase: "Experience the true essence of Kerala's culinary artistry with Chef Prime Rice Powder. Our commitment to quality begins with the meticulous sourcing of premium White Raw rice, ensuring a foundation of authentic flavor for beloved dishes like Idiyappam and Pathiri. Beyond breakfast, this versatile rice powder caters to a spectrum of culinary delights, seamlessly transitioning from morning indulgences to evening snacks.",
        img: pro2
    },
]