import React from "react";

const Card = ({data,content}) => {
  return (
    <div className="w-[170px] border-2 border-primary rounded-full p-8 font-serif capitalize" data-aos = "fade-up"
    data-aos-duration="800">
      <h1 className="font-extrabold text-[30px]">{data}</h1>
      <h3 className="font-semibold text-primary text-xl">{content}</h3>
    </div>
  );
};

export default Card;
