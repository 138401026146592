import Aos from "aos";
import '../../App.css'
import React, { useEffect, useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { Products } from "../../data/Products";

const Carousal = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const [visibleCards, setVisibleCards] = useState(5); // Number of visible cards at a time

  const handleNext = () => {
    if (visibleIndex + visibleCards < Products.length) {
      setVisibleIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (visibleIndex > 0) {
      setVisibleIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (window.innerWidth < 768) {
        setVisibleCards(1); // Change visible cards for mobile screens
      } else {
        setVisibleCards(5); // Reset visible cards for larger screens
      }
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div
      className="container mx-auto md:flex h-full md:items-center relative "
      data-aos="fade-up"
      data-aos-duration="800"
    >
      <div className="no-scrollbar w-full h-full overflow-auto flex justify-center items-center cursor-pointer md:gap-3 focus:outline-none">
        <div
          className="absolute left-0 md:left-5 rounded-full bg-white shadow text-primary hover:bg-primary p-3 hover:text-white outline-none focus:outline-none z-10"
          onClick={handlePrev}
        >
          <MdNavigateNext className="rotate-180" />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="3000"
          className="flex gap-10 scrollbar-hide"
        >
          {Products.slice(visibleIndex, visibleIndex + visibleCards).map((items, i) => (
            <img key={i} src={items?.img} alt="product" className="w-[300px] h-[350px] object-cover " />
          ))}
        </div>
        <div
          className="absolute right-0 md:right-5 rounded-full bg-white shadow text-primary hover:bg-primary p-3 hover:text-white outline-none  z-10"
          onClick={handleNext}
        >
          <MdNavigateNext />
        </div>
      </div>
    </div>
  );
};

export default Carousal;
