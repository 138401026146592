import React, { useState } from "react";
import { MdNavigateNext } from "react-icons/md";
import { productDetails } from "../../data/Products";
import ProductModal from "./ProductModal";

const ProductCarousal = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const visibleCards = 1; // Number of visible cards at a time
  const [viewData, setViewData] = useState({});
  const [modal, setModal] = useState(false);

  const handleNext = () => {
    if (visibleIndex + visibleCards < productDetails.length) {
      setVisibleIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (visibleIndex > 0) {
      setVisibleIndex((prevIndex) => prevIndex - 1);
    }
  };

  const detailedView = (data) => {
    setViewData(data);
    setModal(true);
  };

  return (
    <div className="container mx-auto max-w-[1280px] md:flex h-full md:items-center relative py-5 overflow-hidden">
      <div className="w-full h-full flex justify-center items-center cursor-pointer md:gap-3 focus:outline-none">
        <div
          className="absolute left-1 rounded-full bg-white shadow text-primary hover:bg-primary p-3 hover:text-white outline-none focus:outline-none"
          onClick={handlePrev}
        >
          <MdNavigateNext className="rotate-180" />
        </div>

        <div className="flex flex-col md:flex-row w-full px-5 md:px-16 items-center justify-center">
          {productDetails
            ?.slice(visibleIndex, visibleIndex + visibleCards)
            .map((item) => (
              <>
                <div
                  key={item.id}
                  className="flex flex-col items-center text-center my-5 w-full"
                >
                  <h1 className="font-bold font-serif text-white text-[30px]">
                    {item.name}
                  </h1>
                  <p className="text-white text-start">
                    {item.ContentShowcase}
                  </p>
                  <div className="mt-5">
                    <button
                      onClick={() => detailedView(item)}
                      className="text-white bg-primary shadow p-2 px-10 font-serif rounded hover:scale-105 ease-in-out duration-300"
                    >
                      View
                    </button>
                  </div>
                </div>
                <div className="mt-5">
                  <img
                    src={item.img}
                    alt={item.id}
                    className="w-[450px] md:w-[450px]"
                  />
                </div>
              </>
            ))}
        </div>

        <div
          className="absolute right-1 rounded-full bg-white shadow text-primary hover:bg-primary p-3 hover:text-white outline-none "
          onClick={handleNext}
        >
          <MdNavigateNext />
        </div>
      </div>
      {modal && <ProductModal Modal={setModal} data={viewData} />}
    </div>
  );
};

export default ProductCarousal;
